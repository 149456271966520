import { useEffect, useMemo } from "react";
import Spinner from "./components/atoms/Spinner";
import Login from "./components/screens/Login";
import { useAuth } from "./ctx/Auth";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
import { useApp } from "./ctx/App";
import Verify from "./components/screens/Verify";
import AboutYourself from "./components/screens/AboutYourself";
import AboutBusiness from "./components/screens/AboutBusiness";
import PaymentAcount from "./components/screens/PaymentAcount";
import SignupSuccess from "./components/screens/SignupSuccess";
import axios from "axios";

function FallbackComponent() {
  return (
    <div className="flex flex-col justify-center items-center w-full min-h-screen">
      <p>An error has occurred</p>
      <button
        onClick={() => {
          window?.location?.reload();
        }}
      >
        Reload
      </button>
    </div>
  );
}

const myFallback = <FallbackComponent />;

function App() {
  const {
    state: { isLoggedIn, loading, step },
    actions: { setLoading },
  } = useAuth();

  const {
    state: { componentToRender },
    actions: { updateDetails },
  } = useApp();

  useEffect(() => {
    const getRequirements = async () => {
      try {
        setLoading(true);
        const res = await axios.get("/api/get-onboarding-requirements");
        // console.log(res.data);

        if (Number(res?.data?.status) === 0) {
          const data = res?.data?.data;

          //
          updateDetails({
            type: "business_category",
            value: data?.business_category || [],
          });

          updateDetails({
            type: "business_documents",
            value: data?.business_documents || [],
          });

          updateDetails({
            type: "channels",
            value: data?.channels || [],
          });

          updateDetails({
            type: "gender",
            value: data?.gender || [],
          });

          updateDetails({
            type: "personal_documents",
            value: data?.personal_documents || [],
          });

          updateDetails({
            type: "banks",
            value: data?.settlement?.BANK?.options || [],
          });

          updateDetails({
            type: "mobile_money",
            value: data?.settlement?.MOBILEMONEY?.options || [],
          });

          updateDetails({
            type: "setup_later",
            value: data?.settlement?.NONE?.options || [],
          });
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getRequirements();

    return () => {};
  }, [setLoading, updateDetails]);

  const switchComponentToRender = useMemo(() => {
    // return <AboutYourself key={6} />;
    switch (loading) {
      case null:
        return <Spinner key={0} width={30} height={30} color="#eeefff" />;
      case true:
        return <Spinner key={1} width={30} height={30} />;
      case false:
        if (!isLoggedIn) {
          if (step === 1) {
            return <Login key={2} />;
          }

          if (step === 2) {
            return <Verify key={3} />;
          }
        } else {
          if (componentToRender === "aboutyourself") {
            return <AboutYourself key={4} />;
          }

          if (componentToRender === "aboutbusiness") {
            return <AboutBusiness key={5} />;
          }

          if (componentToRender === "paymentaccount") {
            return <PaymentAcount key={6} />;
          }

          if (componentToRender === "signupsuccess") {
            return <SignupSuccess key={7} />;
          }
        }
        break;
      default:
        return <div>Welcome To Digistore Onboarding</div>;
    }
  }, [componentToRender, isLoggedIn, loading, step]);

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <div className="flex flex-col justify-center items-center w-full min-h-screen">
        {switchComponentToRender}
        <Toaster />
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
