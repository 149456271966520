import React from "react";
import { useForm } from "react-hook-form";

import ErrorMessage from "../atoms/ErrorMessage";
import ButtonSpinner from "../molecules/ButtonSpinner";
import Select from "../atoms/Select";
import Header from "../molecules/Header";
import { useApp } from "../../ctx/App";
import Bank from "../organisams/Bank";
import MobileMoney from "../organisams/MobileMoney";
import { useAuth } from "../../ctx/Auth";
import axios from "axios";
import toast from "react-hot-toast";
import { sendCurrentStageToBE } from "../../utils";

export default function PaymentAcount() {
  const {
    state: { user },
  } = useAuth();

  const {
    state: { aboutyourself, aboutbusiness, paymentaccount, banks },
    actions: { setComponentToRender, updateDetails },
  } = useApp();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: paymentaccount,
  });

  const [processing, setProcessing] = React.useState(false);
  const [loginError, setLoginError] = React.useState({
    status: false,
    message: "",
  });

  const paymentAccount = watch("paymentAccount");

  const handleSignUp = async (values) => {
    // console.log({ values });
    // return;
    try {
      setProcessing(true);
      setLoginError({
        status: false,
        message: "",
      });

      const payload = {
        contact_name: aboutyourself?.name,
        contact_phone: user?.contact_phone,
        contact_email: aboutyourself?.email,
        contact_dob: aboutyourself?.dob,
        contact_gender: aboutyourself?.gender,
        contact_position: aboutbusiness?.title,
        buss_name: aboutyourself?.tradingName,
        // contact_id_type: aboutyourself?.idType,
        contact_id_type: "19",
        contact_id_number: aboutyourself?.idNumber,
        // image_photo: aboutyourself?.idImage?.[0] || "",
        // image_photo: (await getBase64(aboutyourself?.idImage?.[0])) || "",
        buss_gps: aboutbusiness?.gps,
        buss_loc: aboutbusiness?.shopLocation?.label,
        buss_category: aboutbusiness?.businessCategory,
        buss_registered: aboutbusiness?.isBusinessRegistered,
        buss_has_outlet: aboutbusiness?.isBusinessPhysical,
        outlet_contact: aboutbusiness?.businessShopPhoneNumber || "",
        sales_channel: aboutbusiness?.referral,
        sales_referal: aboutbusiness?.referralCode,
        settlement_channel: values?.paymentAccount,
        settlement_institution:
          values?.paymentAccount === "BANK"
            ? values?.bank?.value
            : values.network,
        settlement_institution_branch:
          values?.paymentAccount === "BANK" ? values?.branch?.value : "",
        settlement_institution_branch_code:
          values?.paymentAccount === "BANK"
            ? banks
                ?.find((b) => b?.name === values?.bank?.value)
                ?.branches?.find(
                  (b) => b?.branch_name === values?.branch?.value
                )?.branch_code
            : "",
        settlement_account_no: (
          values?.accountNumber || values?.mobileMoneyNumber
        )
          ?.replaceAll(" ", "")
          ?.replaceAll("+", ""),
        settlement_account_name: values?.accountName || values?.mobileMoneyName,
      };

      // console.log({ payload });
      // return;
      const res = await axios.post("/api/sign-up", payload, {
        maxContentLength: "infinity",
        maxBodyLength: "infinity",
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      // console.log(res.data);
      const data = res?.data;

      if (Number(data?.status) !== 0) {
        return setLoginError({
          status: true,
          message: data?.message,
        });
      }

      toast.success(data?.message);
      setComponentToRender("signupsuccess");
    } catch (error) {
      setLoginError({
        status: true,
        message: "ERROR",
      });

      if (error.response) {
        console.log(error.response?.data);

        toast.error(error.response?.data?.message);
        setLoginError({
          status: true,
          message: error.response?.data?.message,
        });
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col justify-between px-8 lg:px-10 py-10 pt-0">
      <div>
        <Header
          action={() => {
            setComponentToRender("aboutbusiness");
          }}
        />

        <div className="flex items-center justify-center mb-2">
          <div className="flex items-center justify-center w-1/2">
            <div className="rounded-full h-5 w-5 bg-white border border-black"></div>
            <hr className="w-3/12" />
            <div className="rounded-full h-5 w-5 bg-white border border-black"></div>
            <hr className="w-3/12" />
            <div className="rounded-full h-5 w-5 bg-blue-800"></div>
          </div>
        </div>

        <div className="mb-2">
          <p className="text-gray-700 text-center font-bold">
            Setup your payment account
          </p>
          <p className="text-xs text-center font-thin">
            This is where your sales will be transferred to
          </p>
        </div>

        <div className="w-full">
          <Select
            data={[
              {
                name: "Select Bank/Mobile Money",
                value: "",
              },
              {
                name: "Bank",
                value: "BANK",
              },
              {
                name: "Mobile Money",
                value: "MOBILEMONEY",
              },
              {
                name: "Setup Later",
                value: "SETUP_LATER",
              },
            ]}
            {...register(`paymentAccount`, {
              required: `Account is required`,
            })}
          />
          <ErrorMessage text={errors?.paymentAccount?.message} />
        </div>

        <div>
          {paymentAccount === "BANK" && (
            <Bank
              {...{
                register,
                handleSubmit,
                getValues,
                watch,
                setValue,
                errors,
                control,
              }}
            />
          )}

          {paymentAccount === "MOBILEMONEY" && (
            <MobileMoney
              {...{
                register,
                handleSubmit,
                getValues,
                watch,
                setValue,
                errors,
                control,
              }}
            />
          )}
        </div>
      </div>

      <div className="text-center mt-6">
        <div className="w-full flex items-center">
          <input
            type="checkbox"
            {...register("acceptTerms", {
              required: `Please accept terms`,
            })}
          />
          <p className="text-sm font-thin ml-2">
            <span className="mr-1">I agree to our</span>
            <a
              className="font-bold text-blue-800"
              href="https://sell.digistoreafrica.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              className="font-bold text-blue-800"
              href="https://sell.digistoreafrica.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>

        <ErrorMessage classes="mb-2" text={errors?.acceptTerms?.message} />

        {loginError.status && <ErrorMessage text={loginError.message} />}
        <ButtonSpinner
          btnText="Sign Up"
          processing={processing}
          onClick={() => {
            handleSubmit(async (values) => {
              await sendCurrentStageToBE(
                {
                  user_phone: user?.contact_phone,
                  user_data: JSON.stringify({
                    ...aboutyourself,
                    ...aboutbusiness,
                    ...values,
                    shopLocation: aboutbusiness.shopLocation.label,
                    shopLocationGPS: aboutbusiness?.gps,
                  }),
                  user_stage: "SETTLEMENT_DATA",
                },
                (status) => setProcessing(status)
              );
              updateDetails({
                type: "paymentaccount",
                value: getValues(),
              });
              handleSignUp(values);
            })();
          }}
        />

        <div className="flex w-full justify-center mt-3">
          <p>Already have an account? </p>
          <a
            href="digistore://login/web"
            className="text-blue-900 font-bold ml-1"
          >
            {" "}
            Log In
          </a>
        </div>
      </div>
    </div>
  );
}
