import React from "react";
import { BackspaceIcon } from "@heroicons/react/solid";

const Header = ({ action }) => {
  return (
    <button className="items-center w-full pt-10" onClick={action}>
      <BackspaceIcon className="h-10 w-10 text-black" />
      {/* <button className="text-black font-bold text-3xl" onClick={action}> */}

      {/* </button> */}
    </button>
  );
};

export default Header;
