import axios from "axios";

export const getClassesString = (classes) =>
  (classes ?? "")
    .split(" ")
    .map((curr) => `${curr}`)
    .join(" ");

export const getBase64 = (file) => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.addEventListener("load", () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

export const sendCurrentStageToBE = async (params, onProcess) => {
  try {
    onProcess(true);
    await axios.post(`/api/capture-onboarding`, params);
  } catch (error) {
    console.log(error);
  } finally {
    onProcess(false);
  }
};
