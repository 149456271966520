import React, { forwardRef } from "react";
import { useForm } from "react-hook-form";

import { verifyToken } from "../../utils/services";

import { useAuth } from "../../ctx/Auth";
import axios from "axios";
import Logo from "../atoms/Logo";
import toast from "react-hot-toast";

import InputWithLabel from "../molecules/InputWithLabel";
import ErrorMessage from "../atoms/ErrorMessage";
import ButtonSpinner from "../molecules/ButtonSpinner";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useApp } from "../../ctx/App";

export default function Login() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const {
    actions: { loginUser, setLoginStep, updateUser },
  } = useAuth();

  const {
    state: { componentToRender },
    actions: { setComponentToRender },
  } = useApp();

  const [processing, setProcessing] = React.useState(false);
  const [loginError, setLoginError] = React.useState({
    status: false,
    message: "",
  });

  // const PhoneInput = forwardRef((props, ref) => {
  //   return (
  //     <InputWithLabel
  //       ref={ref}
  //       {...props}
  //       labelClasses="!capitalize"
  //       inputClasses="!pt-2 !p-0 !rounded-none !border-b !border-gray-500 !shadow-none hover:focus:!ring-0"
  //     />
  //   );
  // });

  const handleUserSignIn = async (values) => {
    try {
      setProcessing(true);
      setLoginError({
        status: false,
        message: "",
      });

      console.log(values);

      const res = await axios.post("/api/login", values);
      console.log(res.data);
      const data = res?.data;
      // const data = verifyToken(data);

      if (Number(data?.status) !== 0) {
        return setLoginError({
          status: true,
          message: data?.message,
        });
      }

      if (data?.message.includes("Success: User exist")) {
        toast.error(data?.message);
        setLoginError({
          status: true,
          message: data?.message,
        });

        return window.open("digistore://login/web");
      }
      toast.success(data?.message);
      setLoginStep(2);
    } catch (error) {
      setLoginError({
        status: true,
        message: "ERROR",
      });
      if (error.response) {
        console.log(error.response?.data);
        setLoginError({
          status: true,
          message: error.response?.data?.message,
        });
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  const screenWidth = Math.round(window.innerWidth) - 240;

  return (
    <div className="w-full  min-h-screen flex flex-col justify-around px-8 lg:px-10 py-10 pt-0">
      <div className="">
        <div className="flex justify-center items-center w-full mb-3">
          <div
            style={{
              width: screenWidth,
            }}
          >
            <Logo className="" />
          </div>
        </div>
        <p className=" text-gray-700 text-center mb-6 px-8">
          Create ONE Account to mangage your sales, inventory, payments,
          customers and loyalty
        </p>
      </div>

      <div className="w-full mb-3">
        <div className="flex justify-center  border-red-500 w-full">
          <div className="w-11/12">
            <p className="text-sm text-gray-700 text-center mb-6 px-6">
              Enter your Mobile Number to Get Started
            </p>
            <PhoneInput
              // inputComponent={PhoneInput}
              international={true}
              withCountryCallingCode={true}
              defaultCountry="GH"
              placeholder="Mobile Number"
              {...register("contact_phone", {
                required: "Mobile number is required",
                validate: {
                  validNumber: (value) =>
                    isPossiblePhoneNumber(value) || "Number is not valid",
                },
              })}
              onChange={(value) =>
                setValue("contact_phone", value, {
                  shouldValidate: true,
                })
              }
            />
          </div>
        </div>
        <ErrorMessage text={errors?.contact_phone?.message} />
      </div>

      <div className="text-center">
        {loginError.status && <ErrorMessage text={loginError.message} />}
        <ButtonSpinner
          btnText="Continue"
          processing={processing}
          onClick={handleSubmit((values) => {
            updateUser({
              contact_phone: values?.contact_phone
                ?.replaceAll(" ", "")
                ?.replaceAll("+", ""),
            });
            handleUserSignIn({
              contact_phone: values?.contact_phone
                ?.replaceAll(" ", "")
                ?.replaceAll("+", ""),
            });
            // setLoginStep(2);
          })}
        />

        <div className="flex w-full justify-center mt-3">
          <p>Already have an account? </p>
          <a
            href="digistore://login/web"
            className="text-blue-900 font-bold ml-1"
          >
            {" "}
            Log In
          </a>
        </div>
      </div>
    </div>
  );
}
