import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import ErrorMessage from "../atoms/ErrorMessage";
import ButtonSpinner from "../molecules/ButtonSpinner";
import Input from "../atoms/Input";
import Select from "../atoms/Select";
import Header from "../molecules/Header";
import { useApp } from "../../ctx/App";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
} from "react-google-places-autocomplete";

import MapPicker from "react-google-map-picker";
import { useAuth } from "../../ctx/Auth";
import { sendCurrentStageToBE } from "../../utils";

const DefaultLocation = { lat: 5.616167660305114, lng: -0.6689078645315116 };
const DefaultZoom = 11;

export default function AboutBusiness() {
  const autocompleteRef = useRef(null);

  const {
    state: { user },
  } = useAuth();

  const {
    state: {
      aboutyourself,
      aboutbusiness,
      business_category = [],
      channels = [],
    },
    actions: { setComponentToRender, updateDetails },
  } = useApp();

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: aboutbusiness,
  });

  const [processing, setProcessing] = React.useState(false);

  const [location, setLocation] = useState(DefaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [showMap] = React.useState(false);
  const [formattedAddress, setFormattedAddress] = useState(
    aboutbusiness?.formatted_address
  );

  // useEffect(() => {
  //   reset({
  //     ...aboutbusiness,
  //     shopLocation: aboutbusiness?.shopLocation?.label
  //       ? aboutbusiness?.shopLocation
  //       : "",
  //   });

  //   return () => {};
  // }, [aboutbusiness, reset]);

  async function handleChangeLocation(lat, lng) {
    //
    console.log({ lat, lng });
    setLocation({ lat: lat, lng: lng });

    const res = await geocodeByLatLng({ lat, lng });
    const formatted_address = res?.[0]?.formatted_address;

    //
    console.log({ formatted_address });
    setFormattedAddress(formatted_address || "");
    setValue("shopLocation", formatted_address);

    const gps = `${lat},${lng}`;
    setValue("gps", gps);
    setValue("formatted_address", formatted_address);

    updateDetails({
      type: "aboutbusiness",
      value: {
        ...getValues(),
        // gps: gps,
        // formatted_address: formatted_address,
        // shopLocation: formatted_address || "",
      },
    });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  const isBusinessPhysical = watch("isBusinessPhysical");

  return (
    <div className="w-full min-h-screen flex flex-col justify-between px-8 lg:px-10 py-10 pt-0">
      <div>
        <Header
          action={() => {
            setComponentToRender("aboutyourself");
          }}
        />

        <div className="flex items-center justify-center mb-2">
          <div className="flex items-center justify-center w-1/2">
            <div className="rounded-full h-5 w-5 bg-white border border-black"></div>
            <hr className="w-3/12" />
            <div className="rounded-full h-5 w-5 bg-blue-800"></div>
            <hr className="w-3/12" />
            <div className="rounded-full h-5 w-5 bg-white border border-black"></div>
          </div>
        </div>

        <div className="">
          <p className=" text-center">Tell us about your business</p>
        </div>

        <div className="w-full my-3">
          <Select
            data={[
              {
                name: "Select the Industry",
                value: "",
              },
              ...business_category?.map((x) => ({
                name: x?.category_name,
                value: x?.category_id,
              })),
            ]}
            {...register("businessCategory", {
              required: `Industry is required`,
            })}
          />
          <ErrorMessage text={errors?.businessCategory?.message} />
        </div>

        <div className="flex items-center w-full">
          <div className={`w-full transition-all duration-150`}>
            <Controller
              control={control}
              name="shopLocation"
              rules={{
                required: {
                  value: true,
                  message: `Shop location is required`,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <GooglePlacesAutocomplete
                    ref={autocompleteRef}
                    apiKey="AIzaSyCEhoYQkAxqs75nVsS_xUWg2w5DVFZ_p_4"
                    onLoadFailed={(error) =>
                      console.error("Could not inject Google script", error)
                    }
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["gh"],
                      },
                    }}
                    selectProps={{
                      styles: {
                        singleValue: (base) => ({
                          ...base,
                          fontSize: 14,
                          fontWeight: 300,
                        }),
                        option: (base) => ({
                          ...base,
                          fontSize: 13,
                          fontWeight: 300,
                        }),
                        control: (base) => ({
                          ...base,
                          fontSize: 14,
                        }),
                        placeholder: (base) => ({
                          ...base,
                        }),
                      },
                      placeholder: "Enter your business location",
                      value,
                      onChange: (e) => {
                        e?.value?.place_id &&
                          geocodeByPlaceId(e?.value?.place_id)
                            .then((results) => {
                              const lat =
                                results?.[0]?.geometry?.location?.lat();
                              const lng =
                                results?.[0]?.geometry?.location?.lng();

                              setLocation({ lat, lng });
                              setValue("gps", `${lat},${lng}`);

                              updateDetails({
                                type: "aboutbusiness",
                                value: { ...getValues(), gps: `${lat},${lng}` },
                              });
                            })
                            .catch((error) => console.error(error));

                        onChange(e);
                      },
                      isClearable: true,
                      inputValue: formattedAddress,
                      onInputChange: setFormattedAddress,
                    }}
                  />
                </>
              )}
            />
            {errors[`shopLocation`] && (
              <p className="text-xs text-red-500">
                {errors?.shopLocation?.message}
              </p>
            )}
          </div>
          {/* <button
            className="text-[10px] bg-blue-800 rounded-sm text-white ml-1 font-bold p-1 h-full"
            onClick={() => setShowMap((data) => !data)}
          >
            {showMap ? "Hide Map" : "Choose on map"}
          </button> */}
        </div>

        {showMap && (
          <div className="w-full my-3">
            <>
              <MapPicker
                defaultLocation={location}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: 400 }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                apiKey="AIzaSyCEhoYQkAxqs75nVsS_xUWg2w5DVFZ_p_4"
              />
            </>
          </div>
        )}

        <div className="w-full my-3">
          <p className="text-sm  font-thin mb-1">
            Is your business registered?
          </p>

          <label
            htmlFor="isBusinessRegistered1"
            className="mr-16 text-gray-500 text-sm font-thin"
          >
            <input
              className=" text-sm"
              {...register("isBusinessRegistered", {
                required: `Registration status is required`,
              })}
              type="radio"
              value="yes"
              id="isBusinessRegistered1"
            />
            Yes
          </label>

          <label
            htmlFor="isBusinessRegistered2"
            className=" text-gray-500 text-sm font-thin"
          >
            <input
              {...register("isBusinessRegistered", {
                required: `Registration status is required`,
              })}
              type="radio"
              value="no"
              id="isBusinessRegistered2"
            />
            No
          </label>
        </div>

        {/* {isBusinessRegistered === "yes" && (
          <div className="w-full my-3">
            <div className="w-full my-3">
              <Select
                data={[
                  {
                    name: "Business Registration Certificate",
                    value: "",
                  },
                  {
                    name: "Business C",
                    value: "busC",
                  },
                  {
                    name: "Business D",
                    value: "busD",
                  },
                ]}
                {...register("businessDocType", {
                  required:
                    isBusinessRegistered === "yes" &&
                    `Business document type is required`,
                })}
              />
              <ErrorMessage text={errors?.businessDocType?.message} />
            </div>

            <div className="w-full my-3">
              <Input
                placeholder="Enter Business Registration Number/TIN Number"
                {...register("businessShopPhoneNumber", {
                  required: `Business reg. number is required`,
                })}
              />
              <ErrorMessage text={errors?.businessShopPhoneNumber?.message} />
            </div>

            <div className="w-full my-1">
              <label className="flex flex-col justify-center w-full h-24 bg-gray-50 rounded-lg border border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 px-4">
                <div className="flex justify-between items-center">
                  <p className="text-sm  font-thin w-2/3">
                    {getValues()?.businessDocImage?.[0]?.name ||
                      "Upload Picture of Business Document"}
                  </p>
                  <p className="font-bold text-gray-900 ml-2 text-lg">+</p>
                </div>
                <input
                  type="file"
                  {...register("businessDocImage", {
                    required: `Business doc image required`,
                  })}
                  className="hidden"
                />
              </label>

              <ErrorMessage text={errors?.businessDocImage?.message} />
            </div>
          </div>
        )} */}

        <ErrorMessage text={errors?.isBusinessRegistered?.message} />

        <div className="w-full my-3">
          <Select
            data={[
              {
                name: "How did you hear about Digistore?",
                value: "",
              },
              ...channels?.map((x) => ({
                name: x,
                value: x,
              })),
            ]}
            {...register("referral", {})}
          />
        </div>

        <div className="w-full my-3">
          <Input
            placeholder="Got a referrral code?"
            {...register("referralCode", {})}
          />
        </div>

        <div className="w-full my-3">
          <Input
            placeholder="What is your position/job title?"
            {...register("title", {
              required: `Title is required`,
            })}
          />
          <ErrorMessage text={errors?.title?.message} />
        </div>

        <div className="w-full my-3">
          <p className="text-sm  font-thin">
            Does your business operate from a physical location?
          </p>

          <label
            htmlFor="isBusinessPhysical1"
            className="mr-16 text-gray-500 text-sm font-thin"
          >
            <input
              className="text-sm"
              {...register("isBusinessPhysical", {
                required: `Business physical status is required`,
              })}
              type="radio"
              value="yes"
              id="isBusinessPhysical1"
            />
            Yes
          </label>

          <label
            htmlFor="isBusinessPhysical2"
            className=" text-gray-500 text-sm font-thin"
          >
            <input
              {...register("isBusinessPhysical", {
                required: `Business physical status is required`,
              })}
              type="radio"
              value="no"
              id="isBusinessPhysical2"
            />
            No
          </label>

          <ErrorMessage text={errors?.isBusinessPhysical?.message} />
        </div>

        {isBusinessPhysical === "yes" && (
          <div className="w-full my-3">
            <p className="text-xs  font-thin">
              This number would receive sms or order notification and phone
              calls
            </p>
            <div className="mt-1">
              <Input
                placeholder="Enter your Shop Number"
                type="number"
                {...register("businessShopPhoneNumber", {
                  required: `Shop phone number is required`,
                  pattern: {
                    value: /^[0]?\d{9}$/,
                    message: "Number must be 0xxxxxxxxx",
                  },

                  minLength: {
                    value: 10,
                    message: "Number must have length of 10",
                  },

                  maxLength: {
                    value: 10,
                    message: "Number must have length of 10",
                  },
                })}
              />
              <ErrorMessage text={errors?.businessShopPhoneNumber?.message} />
            </div>
          </div>
        )}
      </div>

      <div className="text-center mt-6">
        <ButtonSpinner
          btnText="Next"
          processing={processing}
          onClick={() => {
            handleSubmit(async (values) => {
              await sendCurrentStageToBE(
                {
                  user_phone: user?.contact_phone,
                  user_data: JSON.stringify({
                    ...aboutyourself,
                    ...values,
                    shopLocation: values.shopLocation.label,
                    shopLocationGPS: values.shopLocation?.gps,
                  }),
                  user_stage: "BUSINESS_DATA",
                },
                (status) => setProcessing(status)
              );
              setComponentToRender("paymentaccount");
              updateDetails({
                type: "aboutbusiness",
                value: getValues(),
              });
            })();
          }}
        />
      </div>
    </div>
  );
}
