import React from "react";

import ErrorMessage from "../atoms/ErrorMessage";
import Select from "../atoms/Select";
import { useApp } from "../../ctx/App";
import Input from "../atoms/Input";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

export default function MobileMoney({ register, setValue, errors }) {
  const {
    state: { mobile_money },
  } = useApp();

  const [processing, setProcessing] = React.useState(false);

  return (
    <div className="w-full">
      <div className="w-full my-3">
        <Select
          data={[
            {
              name: "Select Mobile Money Provider",
              value: "",
            },
            ...mobile_money?.map((x) => ({
              name: x?.name,
              value: x?.code,
            })),
          ]}
          {...register(`network`, {
            required: `Network is required`,
          })}
        />
        <ErrorMessage text={errors?.network?.message} />
      </div>

      <div className="w-full mb-3">
        <div className="w-full">
          <PhoneInput
            international={true}
            withCountryCallingCode={true}
            defaultCountry="GH"
            placeholder="Phone Number"
            {...register("mobileMoneyNumber", {
              required: "Mobile number is required",
              validate: {
                validNumber: (value) =>
                  isPossiblePhoneNumber(value) || "Number is not valid",
              },
            })}
            onChange={(value) =>
              setValue("mobileMoneyNumber", value, {
                shouldValidate: true,
              })
            }
          />
        </div>

        <ErrorMessage text={errors?.mobileMoneyNumber?.message} />
      </div>

      <div className="w-full my-3">
        <Input
          type="text"
          placeholder="Enter Mobile Money Name"
          {...register("mobileMoneyName", {
            required: `Name is required`,
          })}
        />
        <ErrorMessage text={errors?.mobileMoneyName?.message} />
      </div>
    </div>
  );
}
