import React from "react";

import success from "../../assets/success_logo.png";

export default function SignupSuccess() {
  return (
    <div className="w-full min-h-screen flex flex-col justify-around px-10 lg:px-10">
      <div className="flex justify-center flex-col items-center">
        <img className="w-7/12 h-1/2" src={success} alt="success" />
        <h1 className="text-blue-800 text-center font-bold text-2xl">
          Congratulations
        </h1>
      </div>

      <div>
        <p className="text-sm text-center">
          Your account has been successfully created and{" "}
          <span className="font-bold"> Pending Account Activation.</span>
        </p>

        <br />
        <p className="text-sm text-center">
          We are reviewing and will activate or get back to you within 24hrs to
          enable you access all our features. In the meantime, Get started with
          <span className=""> Digistore POS by:</span>
        </p>

        <ul className="text-center underline list-disc list-inside mt-5">
          <li>Add your products</li>
          <li>Recording cash sales</li>
          <li>Capture Customer Details</li>
          <li>Send SMS and Email receipts</li>
        </ul>

        {/* <div className="flex justify-center items-center w-full mb-3">
          <a
            href="https://play.google.com/store/apps/details?id=com.ipayghana"
            className="w-[150px] mr-4"
          >
            <img src={GoogleStore} alt="logo" />
          </a>
          <a
            href="https://apps.apple.com/us/app/digistore-pos-point-of-sale/id1528883347"
            className="w-[150px]"
          >
            <img src={AppStore} alt="logo" />
          </a>
        </div> */}
      </div>

      <div className="text-center mt-6 w-full">
        <a
          href="digistore://login/web"
          className="bg-[#4AB647] text-white px-10 py-2 rounded w-full"

          // onClick={() => {
          //   reset({
          //     loading: false,
          //     isLoggedIn: false,
          //     user: null,
          //     showHeader: false,
          //     step: 1,
          //   });
          //   return window.open(
          //     "https://play.google.com/store/apps/details?id=com.ipayghana"
          //   );
          // }}
        >
          Login
        </a>
      </div>
    </div>
  );
}
