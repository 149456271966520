import React from "react";
import { getClassesString } from "../../utils";

const ErrorMessage = ({ text, classes, ...props }) => {
  return (
    <p
      dangerouslySetInnerHTML={{ __html: text }}
      className={`text-xs text-red-500 ${getClassesString(classes)}`}
      {...props}
    />
  );
};

export default ErrorMessage;
