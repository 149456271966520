import { TailSpin } from "react-loader-spinner";

const Spinner = ({
  color = "#000",
  height = 100,
  width = 100,
  timeout = 3000000,
}) => {
  return (
    <TailSpin color={color} height={height} width={width} timeout={timeout} />
  );
};

export default Spinner;
