import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import ErrorMessage from "../atoms/ErrorMessage";
import ButtonSpinner from "../molecules/ButtonSpinner";
import Input from "../atoms/Input";
import Select from "../atoms/Select";
import Header from "../molecules/Header";
import { useApp } from "../../ctx/App";
import { capitalize } from "lodash";
import { useAuth } from "../../ctx/Auth";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import { getBase64, sendCurrentStageToBE } from "../../utils";

const ImageComp = ({
  getValues,
  register,
  errors,
  addImageDataURl,
  idImageDataURL,
}) => {
  const [value, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const imageName = useMemo(
    () => getValues()?.idImage?.[0]?.name,
    [getValues, value]
  );
  // console.log("render", imageName);

  const imageRegister = {
    ...register("idImage", {
      validate: {
        positive: (v) => {
          return v?.length > 0 || `Ghana Card ID Image required`;
        },
      },
    }),
  };
  return (
    <div className="w-full my-3">
      <label className="flex flex-col justify-center w-full h-24 bg-gray-50 rounded-lg border border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 px-4">
        <div className="flex justify-between items-center">
          <p className="text-sm  font-thin w-2/3">
            {imageName || "Upload Picture of Ghana Card ID"}
          </p>
          <div>
            {idImageDataURL && (
              <div className="border border-gray-300 rounded">
                <img src={idImageDataURL} alt="" className="w-full h-20" />
              </div>
            )}
          </div>
          <p className="font-bold text-gray-900 ml-2 text-lg">+</p>
        </div>
        <input
          type="file"
          accept="image/*"
          className="hidden"
          {...imageRegister}
          onChange={(e) => {
            imageRegister?.onChange(e);
            addImageDataURl(e?.target?.files?.[0]);

            forceUpdate();
          }}
        />
      </label>

      <ErrorMessage text={errors?.idImage?.message} />
    </div>
  );
};

export default function AboutYourself() {
  const {
    state: { user },
    actions: { resetUserIsLoggedin, checkAuth },
  } = useAuth();

  const {
    state: { aboutyourself, gender = [], personal_documents = [] },
    actions: { setComponentToRender, updateDetails },
  } = useApp();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: aboutyourself,
  });

  const [processing, setProcessing] = React.useState(false);
  const [idImageDataURL, setIdImageDataURL] = useState(
    aboutyourself?.imageDataURL || null
  );

  return (
    <div className="w-full min-h-screen flex flex-col justify-between px-8 lg:px-10 py-10 pt-0">
      <div>
        <Header
          action={() => {
            resetUserIsLoggedin();
            checkAuth();
          }}
        />

        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center w-1/2">
            <div className="rounded-full h-5 w-5 bg-blue-800"></div>
            <hr className="w-3/12" />
            <div className="rounded-full h-5 w-5 bg-white border border-black"></div>
            <hr className="w-3/12" />
            <div className="rounded-full h-5 w-5 bg-white border border-black"></div>
          </div>
        </div>

        <div className="w-full my-3">
          <div className="flex justify-center items-center w-full mb-3">
            <div className="">
              <p>
                <span className="text-xl text-blue-800 font-bold italic">
                  Welcome to Digi
                </span>
                <span className="text-xl text-green-500 font-bold">store</span>
              </p>
            </div>
          </div>

          <p className="text-gray-700 text-center">Tell us about yourself</p>
        </div>

        <div className="w-full my-3">
          <Input
            placeholder="Enter Business Trading Name"
            {...register("tradingName", {
              required: `Trading name is required`,
              validate: {
                checkName: async (v) => {
                  try {
                    if (!v) return true;
                    setProcessing(true);
                    const res = await axios.post(
                      `/api/check-trading-name?business_name`,
                      {
                        business_name: encodeURIComponent(v),
                      }
                    );
                    // console.log(res.data);
                    if (Number(res?.data?.status) === 0) {
                      return true;
                    } else {
                      return res?.data?.message;
                    }
                  } catch (error) {
                    console.log(error);
                    return "An error occured";
                  } finally {
                    setProcessing(false);
                  }
                },
              },
            })}
          />
          <ErrorMessage text={errors?.tradingName?.message} />
        </div>

        <div className="w-full my-3">
          <Input
            placeholder="Enter First and Last Names"
            {...register("name", {
              required: `Name is required`,
            })}
          />
          <ErrorMessage text={errors?.name?.message} />
        </div>

        <div className="w-full my-3">
          <Select
            data={[
              {
                name: "Select Gender",
                value: "",
              },
              ...gender?.map((x) => ({
                name: capitalize(x),
                value: x,
              })),
            ]}
            {...register("gender", {
              required: `Select gender`,
            })}
          />
          <ErrorMessage text={errors?.gender?.message} />
        </div>

        <div className="date-picker w-full mt-3" tabIndex="0">
          <input
            required
            type="date"
            {...register("dob", {
              required: `Select date of birth`,
            })}
          />
          <div className="placeholder"></div>
        </div>
        <ErrorMessage text={errors?.dob?.message} />

        {/* <div className="w-full my-3">
          <Input
            placeholder="Select Date of Birth"
            type="date"
            {...register("dob", {
              required: `Select date of birth`,
            })}
            // onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
          />
          <ErrorMessage text={errors?.dob?.message} />
        </div> */}

        <div className="w-full my-3">
          <Input
            placeholder="Enter Email Address (Optional)"
            {...register("email", {})}
          />
        </div>

        {/* <div className="w-full my-3">
          <Select
            data={[
              {
                name: "Select ID card Type",
                value: "",
              },
              ...personal_documents?.map((x) => ({
                name: x?.document_name,
                value: x?.document_id,
              })),
            ]}
            {...register("idType", {
              required: `ID type required`,
            })}
          />
          <ErrorMessage text={errors?.idType?.message} />
        </div> */}

        <div className="w-full my-3">
          <Input
            placeholder="Enter your Ghana Card ID"
            {...register("idNumber", {
              required: `Enter your Ghana Card ID Number`,
              validate: {
                // length: (value) => {
                //   return (
                //     (value?.length === 15 &&
                //       value.startsWith("GHA") &&
                //       value.includes("-")) ||
                //     "ID number must be 15 characters long, must start with GHA and must contain hyphens"
                //   );
                // },
                // checkNumber: async (v) => {
                //   try {
                //     setProcessing(true);
                //     const res = await axios.post("/api/check-nia-card", {
                //       id: v,
                //       type: "NIA",
                //     });
                //     console.log(res.data);
                //     if (Number(res?.data?.status) === 0) {
                //       return res?.data?.valid || res?.data?.message;
                //     } else {
                //       return res?.data?.message;
                //     }
                //   } catch (error) {
                //     console.log(error);
                //     return "An error occured";
                //   } finally {
                //     setProcessing(false);
                //   }
                // },
              },
            })}
          />
          <p className="text-xs mt-1">Format: GHA-XXXXXXXXX-X</p>
          <ErrorMessage text={errors?.idNumber?.message} />
        </div>

        {/* <div className="w-full my-3">
          <label className="flex flex-col justify-center w-full h-24 bg-gray-50 rounded-lg border border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 px-4">
            <div className="flex justify-between items-center">
              <p className="text-sm  font-thin w-2/3">
                {imageName || "Upload Picture of ID Card"}
              </p>
              <p className="font-bold text-gray-900 ml-2 text-lg">+</p>
            </div>
            <input
              type="file"
              {...register("idImage", {
                validate: {
                  positive: (v) => {
                    return v?.length > 0 || `ID Image required`;
                  },
                },
              })}
              className="hidden"
            />
          </label>

          <ErrorMessage text={errors?.idImage?.message} />
        </div> */}

        {/* <ImageComp
          errors={errors}
          getValues={getValues}
          register={register}
          idImageDataURL={idImageDataURL}
          addImageDataURl={async (file) => {
            const url = await getBase64(file);
            setIdImageDataURL(url);

            updateDetails({
              type: "aboutyourself",
              value: { ...aboutyourself, imageDataURL: url },
            });
          }}
        /> */}

        {/* <div>
          {idImageDataURL && (
            <div className="border border-gray-300 rounded">
              <img src={idImageDataURL} alt="" className="w-full" />
            </div>
          )}
        </div> */}

        {/* <Controller
          control={control}
          name="idImage"
          rules={{
            validate: {
              positive: (v) => {
                console.log({ v });
                return v?.length > 0 || `ID Image required`;
              },
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <ImageUploading
                  multiple
                  value={value}
                  onChange={(v) => {
                    console.log(v);
                    onChange(v);
                  }}
                  maxNumber={1}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <>
                      <div className="w-full mt-3">
                        <label className="flex flex-col justify-center w-full h-24 bg-gray-50 rounded-lg border border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 px-4">
                          <button
                            className="flex justify-between items-center"
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <p className="text-sm  font-thin w-2/3">
                              {getValues()?.idImage?.[0]?.file?.name ||
                                "Upload Picture of ID Card"}
                            </p>
                            <p className="font-bold text-gray-900 ml-2 text-lg">
                              +
                            </p>
                          </button>
                        </label>
                      </div>

                      {imageList?.length ? (
                        <button
                          onClick={onImageRemoveAll}
                          className="text-center text-red-500 flex w-full text-xs mt-1"
                        >
                          Remove image
                        </button>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </ImageUploading>
                <ErrorMessage text={errors?.idImage?.message} />
              </>
            );
          }}
        /> */}
      </div>
      <div className="text-center mt-6">
        <ButtonSpinner
          processing={processing}
          btnText="Next"
          onClick={() => {
            handleSubmit(async (values) => {
              await sendCurrentStageToBE(
                {
                  user_phone: user?.contact_phone,
                  user_data: JSON.stringify(values),
                  user_stage: "PERSONAL_DATA",
                },
                (status) => setProcessing(status)
              );
              setComponentToRender("aboutbusiness");
              updateDetails({
                type: "aboutyourself",
                value: { ...aboutyourself, ...getValues() },
              });
            })();
          }}
        />
      </div>
    </div>
  );
}
