import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";

import { useAuth } from "../../ctx/Auth";
import axios from "axios";
import Logo from "../atoms/Logo";
import toast from "react-hot-toast";

import ErrorMessage from "../atoms/ErrorMessage";
import ButtonSpinner from "../molecules/ButtonSpinner";
import { useApp } from "../../ctx/App";
import { useCountdown } from "../../hooks/useCountdown";

const now = new Date();
const addTwoMinutes = new Date(now.getTime() + 2 * 60000);

const CountDown = ({ timeLeft, didntReceiveCode }) => {
  const [day, hours, minutes, seconds] = useCountdown(timeLeft);

  return (
    <div className="flex justify-center mt-4">
      {minutes + seconds <= 0 ? (
        <p className="mr-2">0 : 00</p>
      ) : (
        <p className="mr-2">
          {minutes} {":"} {seconds}
        </p>
      )}

      <button
        disabled={minutes + seconds > 0}
        onClick={didntReceiveCode}
        className={`${
          minutes + seconds <= 0 ? "text-blue-500" : "text-gray-500"
        } font-thin`}
      >
        I didn't receive the code
      </button>
    </div>
  );
};

export default function Verify() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  const pin = React.useRef({});
  pin.current = watch("pin", "");

  const {
    state: { isLoggedIn, loading, user, showHeader, step },
    actions: { setLoginStep, checkAuth, updateUser },
  } = useAuth();

  const {
    state: { componentToRender },
    actions: { setComponentToRender },
  } = useApp();

  const [processing, setProcessing] = React.useState(false);
  const [loginError, setLoginError] = React.useState({
    status: false,
    message: "",
  });

  const [timeLeft, setTimeLeft] = useState(addTwoMinutes);

  const didntReceiveCode = async () => {
    try {
      setProcessing(true);
      setLoginError({
        status: false,
        message: "",
      });

      const res = await axios.post("/api/login", {
        contact_phone: user?.contact_phone,
      });

      const data = res?.data;

      if (Number(data?.status) !== 0) {
        return setLoginError({
          status: true,
          message: data?.message,
        });
      }

      toast.success(data?.message);
      setTimeLeft(new Date(new Date().getTime() + 2 * 60000));
    } catch (error) {
      setLoginError({
        status: true,
        message: "ERROR",
      });
      if (error.response) {
        console.log(error.response?.data);
        setLoginError({
          status: true,
          message: error.response?.data?.message,
        });
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  const validateOTP = async (values) => {
    try {
      setProcessing(true);
      setLoginError({
        status: false,
        message: "",
      });

      // console.log(values);

      const res = await axios.post("/api/verify-otp", {
        contact_phone: user?.contact_phone,
        otp: values?.otp,
      });

      console.log(res.data);
      const data = res?.data;
      // const data = verifyToken(data);

      if (Number(data?.status) !== 0) {
        return setLoginError({
          status: true,
          message: data?.message,
        });
      }

      toast.success(data?.message);
      updateUser({
        isLoggedIn: true,
      }); // very important
      checkAuth();
      setComponentToRender("aboutyourself");
    } catch (error) {
      setLoginError({
        status: true,
        message: "ERROR",
      });
      if (error.response) {
        console.log(error.response?.data);
        setLoginError({
          status: true,
          message: error.response?.data?.message,
        });
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  const screenWidth = Math.round(window.innerWidth) - 240;

  return (
    <div className="w-full  min-h-screen flex flex-col justify-around px-8 lg:px-10 py-10 pt-0">
      <div className="">
        <div className="flex justify-center items-center w-full mb-3">
          <div
            style={{
              width: screenWidth,
            }}
          >
            <Logo className="" />
          </div>
        </div>

        <p className="text-sm text-gray-700 text-center mb-6 px-6">
          For security, help us ensure only you can log in into your account
        </p>
        <p className="text-sm text-gray-700 text-center mb-6 px-6">
          Enter the 6-digit code sent to you at:
          <br></br>
          <b>+{user?.contact_phone}</b>
        </p>
      </div>

      <div className="w-full mb-3 ">
        <div className=" flex justify-center">
          <Controller
            control={control}
            name="otp"
            rules={{
              required: {
                value: true,
                message: `OTP is required`,
              },
              minLength: {
                value: 6,
                message: "OTP must have length of 6",
              },
              maxLength: {
                value: 6,
                message: "OTP must have length of 6",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <OtpInput
                  isInputNum
                  value={value}
                  onChange={
                    (otp) => onChange(otp)
                    // setValue("otp", otp, {
                    //   shouldValidate: true,
                    // })
                  }
                  numInputs={6}
                  separator={<span>-</span>}
                  inputStyle={{
                    borderColor: "black",
                    borderWidth: 1,
                    borderRadius: 2,
                    height: 40,
                    width: 40,
                  }}
                  hasErrored={!!errors?.otp?.message}
                  shouldAutoFocus={true}
                  isDisabled={processing}
                  errorStyle={{
                    borderColor: "red",
                    borderWidth: 1,
                  }}
                />
              </>
            )}
          />
        </div>
        <div className="flex w-full justify-center mt-1">
          <ErrorMessage text={errors?.otp?.message} />
        </div>
        <CountDown timeLeft={timeLeft} didntReceiveCode={didntReceiveCode} />
      </div>

      <div className="text-center mt-6">
        {loginError.status && <ErrorMessage text={loginError.message} />}
        <ButtonSpinner
          btnText="Verify Code"
          processing={processing}
          onClick={handleSubmit((values) => {
            updateUser(values);
            validateOTP(values);
          })}
        />

        <div className="flex w-full justify-center mt-3">
          <button
            onClick={() => {
              setLoginStep(1);
            }}
          >
            I want to change my number
          </button>
        </div>
      </div>
    </div>
  );
}
