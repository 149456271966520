import { createContext, useContext, useMemo, useReducer } from "react";

const initialState = {
  loading: null,
  isLoggedIn: false,
  user: null,
  showHeader: false,
  step: 1,
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "LOADING":
        return {
          ...state,
          loading: action.payload,
        };

      case "LOGIN_STEP":
        return {
          ...state,
          step: action.payload,
        };

      case "UPDATE_USER_DETAILS":
        return {
          ...state,
          user: { ...(state?.user || {}), ...action.payload },
        };

      case "RESET":
        return {
          ...state,
          ...action.payload,
        };

      case "RESET_USER_ISLOGGEDIN":
        return {
          ...state,
          user: {
            ...state?.user,
            isLoggedIn: false,
          },
        };

      case "CHECK_AUTH":
        if (state.user?.isLoggedIn) {
          return {
            ...state,
            loading: false,
            isLoggedIn: true,
          };
        } else {
          return {
            ...state,
            loading: false,
            isLoggedIn: false,
          };
        }

      case "LOGIN":
        return {
          ...state,
          loading: false,
          isLoggedIn: "verified" in action.payload ? true : false,
          user: "user" in action.payload ? action.payload.user : null,
          showHeader: true,
        };

      case "LOGOUT":
        sessionStorage.removeItem("IPAYDELIVERYPOSUSER");
        return {
          ...state,
          loading: false,
          isLoggedIn: false,
          user: null,
          showHeader: false,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      loginUser: (payload) =>
        dispatch({
          type: "LOGIN",
          payload,
        }),
      logoutUser: (payload) =>
        dispatch({
          type: "LOGOUT",
          payload,
        }),
      setLoading: (payload) =>
        dispatch({
          type: "LOADING",
          payload,
        }),
      checkAuth: () =>
        dispatch({
          type: "CHECK_AUTH",
        }),
      setLoginStep: (payload) =>
        dispatch({
          type: "LOGIN_STEP",
          payload,
        }),

      updateUser: (payload) =>
        dispatch({
          type: "UPDATE_USER_DETAILS",
          payload,
        }),

      reset: (payload) =>
        dispatch({
          type: "RESET",
          payload,
        }),

      resetUserIsLoggedin: () =>
        dispatch({
          type: "RESET_USER_ISLOGGEDIN",
        }),
    }),
    []
  );

  const values = useMemo(
    () => ({
      state,
      actions,
    }),
    [actions, state]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuth = () => useContext(AuthContext);
