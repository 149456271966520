import React, { useMemo } from "react";
import { Controller } from "react-hook-form";

import ErrorMessage from "../atoms/ErrorMessage";
import { useApp } from "../../ctx/App";
import Input from "../atoms/Input";

import ReactSelect from "react-select";

export default function Bank({
  register,
  handleSubmit,
  getValues,
  watch,
  setValue,
  errors,
  control,
}) {
  const {
    state: { banks },
  } = useApp();

  const watchBank = watch("bank", getValues()?.bank || "");

  const branches = useMemo(() => {
    return banks?.find((x) => x?.name === watchBank?.value)?.branches;
  }, [banks, watchBank?.value]);

  return (
    <div className="w-full">
      <div className="w-full my-3">
        <Controller
          control={control}
          name="bank"
          rules={{
            required: {
              value: true,
              message: `Bank is required`,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <ReactSelect
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    fontSize: 14,
                    fontWeight: 300,
                  }),
                  option: (base) => ({
                    ...base,
                    fontSize: 13,
                    fontWeight: 300,
                  }),
                  control: (base) => ({
                    ...base,
                    fontSize: 14,
                  }),
                  placeholder: (base) => ({
                    ...base,
                  }),
                }}
                placeholder="Select Bank"
                options={banks?.map((x) => ({
                  label: x?.name,
                  value: x?.name,
                }))}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue("branch", "");
                }}
              />
            </>
          )}
        />

        <ErrorMessage text={errors?.bank?.message} />
      </div>

      <div className="w-full my-3">
        <Controller
          control={control}
          name="branch"
          rules={{
            required: {
              value: true,
              message: `Branch is required`,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <ReactSelect
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    fontSize: 14,
                    fontWeight: 300,
                  }),
                  option: (base) => ({
                    ...base,
                    fontSize: 13,
                    fontWeight: 300,
                  }),
                  control: (base) => ({
                    ...base,
                    fontSize: 14,
                  }),
                  placeholder: (base) => ({
                    ...base,
                  }),
                }}
                placeholder="Select Branch"
                options={[
                  ...(branches || [])?.map((x) => ({
                    label: x?.branch_name,
                    value: x?.branch_name,
                  })),
                ]}
                value={value}
                onChange={onChange}
              />
            </>
          )}
        />
        <ErrorMessage text={errors?.branch?.message} />
      </div>

      <div className="w-full my-3">
        <Input
          placeholder="Enter Account Name"
          {...register("accountName", {
            required: `Account name is required`,
          })}
        />
        <ErrorMessage text={errors?.accountName?.message} />
      </div>

      <div className="w-full my-3">
        <Input
          type="number"
          placeholder="Enter Account Number"
          {...register("accountNumber", {
            required: `Account number is required`,
          })}
        />
        <ErrorMessage text={errors?.accountNumber?.message} />
      </div>
    </div>
  );
}
