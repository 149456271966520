import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import AuthProvider from "./ctx/Auth";
import AppProvider from "./ctx/App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "react-phone-number-input/style.css";


Sentry.init({
  dsn: "https://3138a16a9bcf467499b64e7f55d0087d@o934257.ingest.sentry.io/5883682",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppProvider>
        <App />
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
